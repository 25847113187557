import { Button, Grid, Popover } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DiceIcon from "../../assets/d10.svg";
import { AppContext } from "../AppContext";
import DiceContext from "./DiceContext";
import { DEFAULT_DICE, diceToLabel, diceToLabelNumeric } from "./utils";

/**
 * A button that rolls the die associated with the given ability
 * @param dices
 * @param short
 * @param type
 * @param from
 * @param position
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function DiceButton(
  {
    dices = DEFAULT_DICE,
    rule = '',
    size = 'middle',
    short = false,
    type = 'primary',
    from = 'default',
    placement = 'top',
    onRoll = null,
    disabled = false,
  }) {
  const brk = Grid.useBreakpoint();
  const context = useContext(AppContext);
  const diceRoller = useContext(DiceContext);
  const [die] = dices.data;

  const [open, setOpen] = useState(false);

  const label = useMemo(() => diceToLabel(die), []);

  const onClick = useCallback(() => {
    onRoll
      ? onRoll(die, from, rule)
      : diceRoller.roll(die, from, rule)
  }, [die]);

  //   show popover only if the last roll is from this button
  useEffect(() => {
    if (!diceRoller.lastRoll) return;
    setOpen(diceRoller.lastRoll?.id === die?.id);
  }, [diceRoller.lastRoll]);

  if (!die) return null;

  return (
    <Popover
      title={diceRoller.lastRoll ? `Résultat: ${diceRoller.result}` : (
        <>
          {diceToLabel(die)}
          <br />
          {diceToLabelNumeric(die, diceRoller.result, context.currentCharacter)}
        </>
      )}
      open={open}
      key={die.id}
      placement={placement || short ? 'left' : placement}
      onOpenChange={setOpen}
    >
      <Button
        id={`dice-${die.id}`}
        className={`dice-button${short ? '-short' : ''}`}
        onClick={onClick}
        disabled={disabled}
        type={type}
        title={label}
        {...(short ? { size: 'small', shape: 'circle' } : {})}
        size={size || (brk.xs ? 'large' : 'middle')}
        style={{
          marginInlineEnd: 0,
          gap: 0,
        }}
        icon={
          <img
            src={DiceIcon}
            alt="1d10"
            title={label}
            width={24}
            style={{
              color: "white",
              fill: 'white',
              verticalAlign: 'middle',
              marginInlineEnd: 0,
              marginRight: short ? 0 : 8
            }}
          />
        }
      >
        {!short && (
          <span style={{ verticalAlign: 'top' }}>
            {label}
          </span>
        )}
      </Button>
    </Popover>
  )
}
