import { Card, Col, Grid, Image, Row, Typography, Badge } from 'antd';
import { DiceButton } from './Dice';
import Markdown from './Markdown';

function ItemCard({ src, previewSrc, attributes, modifiers }) {
  const brk = Grid.useBreakpoint();
  return (
    <CardRibbonWrapper modifiers={modifiers}>
      <Card
        title={(
          <Row justify="space-between">
            <Col>
              <Typography.Text>
                {attributes.name}
              </Typography.Text>
            </Col>
          </Row>
        )}
        cover={
          <Image
            preview
          src={src || previewSrc}
            fallback={<Image preview={false} src={previewSrc} width={200} />}
            placeholder={<Image preview={false} src={previewSrc} />}
            style={{ objectFit: 'cover' }}
            alt={attributes.name}
          />
        }
      >
        <DiceButton dices={attributes.dice_rolls} />
        <Markdown>
          {attributes.description}
        </Markdown>
      </Card>
    </CardRibbonWrapper>
  );
}

export default ItemCard;

function CardRibbonWrapper({ children, modifiers }) {
  if (modifiers) {
    return (
      <Badge.Ribbon text={modifiers}>
        {children}
      </Badge.Ribbon>
    );
  }

  return children;
}
